<template>
  <div :style="divWrapStyle">
    <v-menu :close-on-content-click="false" :max-width="menuMaxWidth" :min-width="menuMinWidth"
            :nudge-left="menuNudgeLeft" :nudge-right="menuNudgeRight" :nudge-top="menuNudgeTop" :offset-y="offsetY"
            :disabled="readonly" :transition="transition" v-if="menuWrapper" v-model="menu">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          @blur="focused = false"
          :class="textFieldClass"
          :clearable="clearable && !readonly && !!dateFormatted"
          @click:append="appendClick"
          @click:append-outer="appendOuterClick"
          @click:clear="date = undefined"
          :color="inputColor"
          :dense="dense"
          @focus="focused = true"
          :hint="hint"
          @input="datePickerChange = false, menu = false"
          @keydown.enter.prevent="menu = false, $emit('enter')"
          :label="label"
          :persistent-hint="persistentHint"
          :prepend-icon="prependIcon"
          :prepend-inner-icon="prependInnerIcon"
          :readonly="readonly"
          :rules="rules || defaultRules"
          :style="textFieldStyle"
          v-bind="{...inputProps, appendIcon, appendOuterIcon}"
          v-model="dateFormatted"
          v-on="on"
        />
      </template>
      <v-date-picker :color="pickerColor" @input="clickedDatePicker(), menu = false" :key="refresh" :no-title="noTitle"
                     :width="pickerWidth" v-model="date"/>
    </v-menu>
    <span v-else>
      <v-text-field
        @blur="focused = false"
        :class="textFieldClass"
        :clearable="clearable && !readonly && !!dateFormatted"
        @click:append="appendClick"
        @click:append-outer="appendOuterClick"
        :color="inputColor"
        :dense="dense"
        @focus="focused = true"
        :hint="hint"
        @input="datePickerChange = false"
        @keydown.enter.prevent="$emit('enter')"
        :label="label"
        :persistent-hint="persistentHint"
        :prepend-icon="prependIcon"
        :prepend-inner-icon="prependInnerIcon"
        :readonly="readonly"
        :rules="rules || defaultRules"
        :style="textFieldStyle"
        v-bind="{...inputProps, appendIcon, appendOuterIcon}"
        v-model="dateFormatted"
      />
      <v-date-picker :color="pickerColor" @input="clickedDatePicker()" :key="refresh" no-title :width="pickerWidth" v-model="date"/>
    </span>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: "DatePickerFormatted",
  props: {
    cleanValue: { default: null },
    clearable: { type: Boolean, default: false },
    dateFormattedProp: String,
    dense: { type: Boolean, default: false },
    dirtyCheck: { type: Boolean, default: false },
    format: {// format to submit and display in text field
      type: String,
      default: 'YYYY-MM-DD'// format used & produced by vuetify v-date-picker
    },
    divWrapStyle: { type: Object, String, default: undefined },
    formatLooselyValid: { type: String, default: undefined },// format with broader set of allowed values ex: M-D-YYYY
    hint: String,
    inputColor: { type: String, default: 'black' },
    inputProps: { type: Object, default: () => ({})},
    label: String,
    menuMaxWidth: { type: String, default: undefined },
    menuMinWidth: { type: String, default: undefined },
    menuNudgeLeft: { type: String, default: undefined },
    menuNudgeRight: { type: String, default: '40' },
    menuNudgeTop: { type: String, default: undefined },
    menuWrapper: { type: Boolean, default: false },
    noTitle: { type: Boolean, default: false },
    offsetY: { type: Boolean, default: false },
    persistentHint: { type: Boolean, default: false },
    prependIcon: { type: String, default: undefined },
    prependInnerIcon: { type: String, default: undefined },
    pickerColor: { type: String, default: 'black' },
    pickerWidth: { type: String, default: undefined },
    textFieldClass: { type: String, default: undefined },
    textFieldStyle: { type: Object, String, default: undefined },
    readonly: { type: Boolean, default: false },
    rules: Array,
    transition: { type: String, default: 'scale-transition' },
  },

  data: function() {
    return {// only parse this.dateFormattedProp and assign to this.date for datePicker if valid format
      date: moment(this.dateFormattedProp, this.format, true).isValid() ? this.parseDate(this.dateFormattedProp) : '',
      dateFormatted: this.dateFormattedProp,
      datePickerChange: false,
      dateToCheck: '',
      focused: false,
      looselyValidFormat: '',
      menu: false,
      refresh: false,
      defaultRules: [(x) => (!x || moment(x, this.format, true).isValid()) || `Must be ${this.format}`],
    }
  },

  computed: {
    appendIcon() {
      if (this.inputProps.appendIcon) return this.inputProps.appendIcon;
      // if (this.clearable && this.focused && !this.readonly && this.dateFormatted) return 'mdi-close-circle';
      // if (this.clearable && !this.readonly && this.dateFormatted) return 'mdi-close';// prefer default - X on hover
    },
    appendOuterIcon() {
      if (this.inputProps.appendOuterIcon) return this.inputProps.appendOuterIcon;
      if (this.dirtyCheck && this.dirty) return 'mdi-arrow-u-left-top';
    },
    dirty() {
      return this.dirtyCheck ? !(this.dateFormatted === this.cleanValue) : false;
    },
  },

  mounted() {
    if (!this.formatLooselyValid) this.formatLooselyValid = this.format;
  },

  watch: {
    date() {
      // if user changes date with datepicker then update text field (dateFormatted), if user changes text field do not
      if (this.datePickerChange) {
        this.dateFormatted = this.formatDate(this.date);
      }
    },

    dateFormatted() {
      this.dateToCheck = this.dateFormatted;

      // what caused dateFormatted to change?
      // 1-dateFormatted changed because user selected a date in datepicker, causing date watch to update it, so ignore
      // 2-dateFormatted changed because user edited the text field, so:
      // looselyValid ? format to add any missing 0s, emit formatted value & update both this.dateFormatted & this.date
      // not looselyValid ? ignore change, just emit inputted value

      if (this.datePickerChange) return;// emit will happen in "date" watcher, so not needed here

      // let looselyValid = moment(this.dateToCheck, this.formatLooselyValid).isValid();//can cause unwanted YYYY change
      let looselyValid = false;
      if (this.formatLooselyValid ==='M-D-YYYY')  {//need to check for missing M & D 0s separately for better results
        looselyValid
            = moment(this.dateToCheck, 'MM-D-YYYY',true).isValid()
            || moment(this.dateToCheck, 'M-DD-YYYY',true).isValid()
            || moment(this.dateToCheck, 'M-D-YYYY', true).isValid()
      }
      this.$emit('loading', true);
      if (looselyValid) {
        this.dateToCheck = this.formatLooselyValid === this.format ? ''
            : moment(this.dateToCheck, this.formatLooselyValid).format(this.format);// change to this.format if diff
        this.date = this.parseDate(this.dateToCheck);// set datepicker to date user entered in text field
        setTimeout(() => {
          this.$emit('loading', false);
          this.$emit('input', this.dateToCheck);
          this.dateFormatted = this.dateToCheck;
        }, 1000);// update dateFormatted
      } else {
        setTimeout(() => {
          this.$emit('loading', false);
          this.$emit('input', this.dateToCheck);
        }, 400);
      }
    },

    dateFormattedProp() {
      if (!this.dateFormattedProp) {
        this.date = null;
        this.dateFormatted = null;
        this.refresh = !this.refresh;
      } else {
        let valid = moment(this.dateFormattedProp, this.format, true).isValid();
        if (valid) {
          this.date = this.parseDate(this.dateFormattedProp)
          this.dateFormatted = this.dateFormattedProp;
          this.refresh = !this.refresh;
        }
      }
    },

  },

  methods: {
    appendClick() {
      // if (this.clearable && this.focused) this.clear();
      if (this.clearable) this.clear();
    },

    appendOuterClick() {
      if (this.dirtyCheck && this.dirty) this.revert();
    },

    clickedDatePicker() {
      this.datePickerChange = true;
    },

    clear() {
      this.datePickerChange = false;
      this.dateFormatted = '';
      this.date = undefined;
      this.refresh = !this.refresh;
    },

    formatDate(date) {// convert date produced by v-date-picker ('YYYY-MM-DD') to this.format
      if (!date) return;
      let dateFormatted = moment(date, 'YYYY-MM-DD').format(this.format);
      this.$emit('input', dateFormatted);
      return dateFormatted;
    },

    parseDate(date) {// convert date in this.format format to format accepted by v-date-picker ('YYYY-MM-DD')
      if (!date) return;
      return moment(date, this.format).format('YYYY-MM-DD');
    },

    revert() {
      this.datePickerChange = false;
      this.dateFormatted = this.cleanValue;// WATCH dateFormatted, & if looselyValid (parsable) will parseDate(), & emit
    },

  }
}
</script>

<style scoped>

</style>