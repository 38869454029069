<template>
  <div>
    <v-card :key="$_sideMenu" eager style="border-top: 1px solid white !important;"
            class="px-0 pt-0 mb-3 d-flex flex-row justify-space-around"
            color="primary" v-sticky sticky-z-index="9" sticky-offset="$_offset" tile
            large dark block>
      <div>
        <v-btn class="px-1 my-n1" @click="save" :disabled="!valid || !anyFieldsEdited() || readonly"
               :loading="saving" :style="{color: readonly ? 'white !important' : ''}" tile text x-large>
          {{ readonly ? 'View' : 'Save' }} Member</v-btn>
      </div>
    </v-card>
    <v-form ref="fields" v-model="valid">
      <v-row class="ma-1" dense>
        <v-col cols="12" md="4" :key="card.title" v-for="(card,index) in cards">
          <v-card class="px-2">
            <v-card-title class="pt-1 pb-3"> {{ card.title }} </v-card-title>
            <v-card-text class="mb-n1 pb-0">
              <span :key="field.name + keyRefreshMask" v-for="field in card.val">
                <date-picker-formatted
                  class="my-3 changedfield"
                  :clean-value="memberClean[field.val]"
                  clearable
                  :date-formatted-prop="member[field.val]"
                  dense
                  :dirty-check="!loading"
                  @enter="!!member.surname ? save() : ''"
                  :format="dateFormat"
                  :format-loosely-valid="dateFormatLooselyValid"
                  :label="field.name"
                  :menu-max-width="'240'"
                  :menu-nudge-left="'259'"
                  :menu-nudge-top="'15'"
                  menu-wrapper
                  no-title
                  offset-y
                  picker-color="secondary"
                  :picker-width="'240'"
                  prepend-inner-icon="mdi-calendar-month"
                  :readonly="readonly"
                  :rules="member[field.val] === memberClean[field.val] ? [] : [rules.date]"
                  :transition="'scroll-x-reverse-transition'"
                  v-if="dates.includes(field.val)"
                  v-model="member[field.val]"
                />
                <smart-input
                  allow-str
                  class="my-3 changedfield"
                  :clean-value="memberClean[field.val]"
                  clearable
                  dense
                  :dirty-check="!loading"
                  @enter="!!member.surname ? save() : ''"
                  :items="$_lists.role"
                  :label="field.name"
                  :mask="field.val === 'ext' ? '##########' : field.val === 'ssn' ? '###-##-####' : '(###) ###-####'"
                  :mask-exists="maskFields.includes(field.val)"
                  :menu-props="{ contentClass: 'compact short' }"
                  :readonly="readonly"
                  @revert="refreshMask()"
                  :rules="field.val === 'surname' ? [rules.required] : dates.includes(field.val) ? [rules.date] : []"
                  :type="field.val === 'role' ? 'VAutocomplete' : 'VTextField'"
                  v-else
                  v-model="member[field.val]"
                />
              </span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row dense class="mx-1 mt-n2" justify="center">
        <v-col cols="12" sm="12">
          <v-card class="px-2">
            <v-card-text class="pb-1">
              <smart-input
                class="changedfield"
                :clean-value="memberClean.email"
                clearable
                dense
                :dirty-check="!loading"
                @enter="!!member.surname ? save() : ''"
                label="Email"
                :readonly="readonly"
                :rules="[rules.email]"
                v-model="member.email"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>

    <br>
    <snack :color="$_snack.color" :msg="$_snack.msg" v-model="$_snack.open" v-if="$_snack.cmpnt==='EditMember'"></snack>
    <ErrorDialog ref="errorDialog"/>

  </div>
</template>

<script>
import ListViewer from '@/components/ListViewer';
import { PeopleAPIService } from "@/servicehandlers/PeopleAPIService";
import moment from "moment";

const peopleAPIService = new PeopleAPIService();

export default {
  name: "EditMember",
  components: { ListViewer },
  props: {
    id: [String, Number],
    default() {
      return {};
    }
  },
  data() {
    return {
      cards: [
        {title: 'Id', val: [
          {name: 'Given Names',val: 'given'},
          {name: 'Surname',    val: 'surname'},
          {name: 'Salutation', val: 'salutation'},
          {name: 'SSN',        val: 'ssn'},
          {name: 'Role',       val: 'role'},
        ]},
        {title: 'Phone', val: [
          {name: 'Direct', val: 'direct'},
          {name: 'Ext.',   val: 'ext'},
          {name: 'Mobile', val: 'mobile'},
          {name: 'Home',   val: 'home'},
          {name: 'Fax',    val: 'fax'},
        ]},
        {title: 'HR', val: [
          {name: 'Licensed', val: 'licensed'},
          {name: 'Hired',    val: 'hired'},
          {name: 'Hours',    val: 'hours'},
          {name: 'CE',       val: 'ce'},
          {name: 'Born',     val: 'born'},
        ],}
      ],
      dates: ['licensed', 'hired', 'born'],
      dateFormat: 'MM-DD-YYYY',
      dateFormatLooselyValid: 'M-D-YYYY',// for DatePickerFormatted.vue to accept and add leading zeros
      keyRefreshMask: 0,// inc num to trigger keyed refresh of masked fields after loading/reverting value -phone,etc
      loading: false,
      member: {},
      memberClean: {},
      maskFields: ['ssn', 'direct', 'ext', 'mobile', 'home', 'fax'],
      readonly: !this.$_can('member-edit'),
      rules: {
        date: (x) => (!x || this.valDate(x)) || `Only valid ${this.dateFormat}`,
        email: (x) => !x || /.+@.+\..+/.test(x) || 'Email must be valid',
        required: (x) => !!x || 'This field is required.',
      },
      saving: false,
      valid: true,
    };
  },

  created() {
    this.getPerson();
  },

  methods: {
    anyFieldsEdited() {// have any of the member fields been edited?
      if (!this.member) return;
      let fieldEdited = false;
      for (let f in this.member) {
        // check each field to see if value != cleanValue (except maskFields--first strip mask from value, then compare)
        if (this.maskFields.includes(f)) {
          let fieldWithAnyMaskRemoved = this.member[f] ? this.member[f].replace(/\D/g, '') : this.member[f];
          if (!this.$_eq(fieldWithAnyMaskRemoved, this.memberClean[f])) fieldEdited = true;
        } else {
          if (!this.$_eq(this.member[f], this.memberClean[f])) fieldEdited = true;
        }
      }
      return fieldEdited;
    },

    getPerson() {
      this.loading = true;
      peopleAPIService.getPerson(this.id, this.$router)
        .then((member) => {
          this.member = JSON.parse(JSON.stringify(member));// member & memberClean need to be set to same value
          this.memberClean = JSON.parse(JSON.stringify(member));
          document.title = `Firm ${this.member.fid} – ${this.readonly ? 'View' : 'Edit'} member`;
          this.keyRefreshMask++;// trigger v-mask to re-render in phone fields
          this.loading = false;
          setTimeout(() => { if (this.$refs.fields) this.$refs.fields.validate() }, 100);
        })
        .catch(e => {
          this.loading = false;
          this.$refs.errorDialog.showError({
            forDevelopers: e
          })
        });
    },

    refreshMask() {
      setTimeout(()=>{ this.keyRefreshMask++ }, 50);// trigger v-mask to re-render in phone fields
    },

    removeMasks(data) {// remove masks for saving to database
      for (let d of data) {
        for (let f of this.maskFields) if (d[f]) d[f] = d[f].replace(/\D/g, '');
      }
      return data;
    },

    save() {
      this.saving = true;
      let memberToSave =  _.cloneDeep(this.member);
      //set all undefined properties to null, or they won't be emptied
      Object.entries(memberToSave).forEach(([key, value]) => {if (value === undefined) memberToSave[key] = null});
      [memberToSave] = this.removeMasks([memberToSave]);// removeMasks expects array
      return peopleAPIService.updatePerson(memberToSave.plid, memberToSave, this.$router)
        .then((data) => {
          this.saving = false;
          this.getPerson();
          this.$store.dispatch('SET_SNACK', {cmpnt: 'EditMember', open: true, msg: 'Member was saved'});
        })
        .catch(e => {
          this.saving = false;
          this.$refs.errorDialog.showError({
            forDevelopers: e
          })
        });
    },

    valDate(date) {
      let valid = moment(date, this.dateFormat, true).isValid();
      return valid;
    },

  }
};
</script>
<style>
</style>
