import CommonAPIService from './CommonHandler';

export class UsersAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  createUser(parms, router) {
    const url = '/api/users';
    return this.CommonAPIService.postCall(url, parms, router);
  }

  deleteUser(userId, router) {
    const url = `/api/users/${userId}`;
    return this.CommonAPIService.deleteCall(url, userId, router);
  }

  getActiveUsers(router, fields='id,username,enabled,ip_restrictions,imusername,shared,company,payroll,auth_groups,multiple_logins,timeout') {
    const url = `/api/users?fields=${fields}&enabled=true`;
    return this.CommonAPIService.getCall(url, null, router);
  }

  getUsernames(router, fields='username') {
    const url = `/api/users?fields=${fields}&enabled=true`;
    return this.CommonAPIService.getCall(url, null, router);
  }

  getEmailByUsername(username, router) {
    const url = `/api/users/email?username=${username}`;
    return this.CommonAPIService.getCall(url, null, router);
  }

  getSigners(router) {
    const url = '/api/users/signers';
    return this.CommonAPIService.getCall(url, router);
  }

  getInactiveUsers(router, fields='id,username,sysuser,enabled,ip_restrictions,imusername,shared,company,payroll') {
    const url = `/api/users?fields=${fields}&enabled=false`;
    return this.CommonAPIService.getCall(url, router);
  }

  getUserACLS(id, router) {
    const url = `/api/users/${id}/acls`;
    return this.CommonAPIService.getCall(url, null, router);
  }

  getUsers(router, fields='id,username,sysuser,enabled,ip_restrictions,imusername,shared,company,payroll,auth_groups,multiple_logins,timeout') {
    const url = `/api/users?fields=${fields}`;
    return this.CommonAPIService.getCall(url, null, router);
  }

  updatePassword(id, currentPassword, newPassword, router) {
    const url = `/api/users/${id}/password`;
    return this.CommonAPIService.putCall(url, {currentPassword, newPassword}, router)
  }

  updateUser(userId, parms, router) {
    const url = `/api/users/${userId}`;
    return this.CommonAPIService.putCall(url, parms, router);
  }

  /**
   * Get hours for users
   * @param {string} action - The action to perform ('Log', 'Daily', 'Period'). [Required]
   * @param {string | null} username - The username for which to get hours. [Optional]
   * @param {string | number |  null } type - The type of hours to retrieve. [Optional]
   * @param { string | date  | null} startDateTime - The start date and time for the period. [Optional]
   * @param {string | date  | null} endDateTime - The end date and time for the period. [Optional]
   * @param {object} router - The router object for API call. [Required]
   * @example getHours('Log', 'john_doe', null, null, null, router);
   * @returns {Promise<*> | *}
   */
  getHours(action, username, type, startDateTime, endDateTime, router) {
    const args = { action, username, type, startDateTime, endDateTime };
    const argsToSend = Object.entries(args).filter(( [_, value] ) =>  value  || parseInt(value) === 0); //if value is truthy or 0 then send as a param
    const params = new URLSearchParams(argsToSend);

    const url = `/api/users/hours?${params.toString()}`;
    return this.CommonAPIService.getCall(url, null, router);
  }
}

export default UsersAPIService;
