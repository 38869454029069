<template>
  <div>
    <v-card class="mt-n3 rounded-0">
      <v-card-title>
        <button-tip xsmall fab icon icolor="black" iname="mdi-update"
                    elevation="5" tip="Refresh Claims" @click="getMembersForThisFirm(firm.fid)"/>
        <v-spacer/>
        <v-text-field append-icon="mdi-magnify" class="my-n3 mr-2" clearable dense hide-details label="Search"
                      single-line style="max-width: 60vw" v-model="search"/>
        <button-tip @click="createNewMember(firm.fid)" color="secondary" elevation="5" fab icolor="white"
                    iname="mdi-plus" tip="Create Member" v-can="'member-edit'" xsmall/>
      </v-card-title>
    </v-card>
    <v-data-table class="elevation-2"
                  :class="[!notPopup ? 'popupLargeTableNoFooter' : 'largeSizeTableNoFooter']"
                  dense
                  fixed-header
                  :headers="colHeaders"
                  hide-default-footer
                  item-key="plid"
                  :items="members"
                  :items-per-page="-1"
                  :loading="loading"
                  loading-text="Data loading... Please wait..."
                  :mobile-breakpoint="0"
                  :search="search"
                  sort-by="sort"
                  :style="$_cssVars"
      >
      <template v-slot:item.actions="{ item }">
        <button-tip @click="editMember(item)" icon iname="mdi-open-in-new" tip="Edit Member"/>
        <button-tip @click="confirmDeleteMember(item)" icolor="red lighten-2" icon
                    iname="mdi-trash-can-outline" tip="Delete Member" v-can="'member-override'"/>
      </template>

      <template v-for="field in fields" v-slot:[`item.${field}`]="props">
                <div v-if="props.item[field] && field === 'sort'">
                  {{ props.item.name}}
                </div>
                <div v-else-if="props.item[field] && field === 'ssn'">
                  {{ props.item[field].replace(/(\d{3})(\d{2})(\d{4})/,'$1-$2-$3') }}
                </div>
                <div v-else-if="props.item[field] && (field === 'mobile' || field === 'direct'
                || field === 'fax' || field === 'home')">
                  {{ props.item[field].replace(/(\d{3})(\d{3})(\d{4})/,'($1) $2-$3') }}
                </div>
                <div v-else>
                  {{ props.item[field] }}
                </div>
      </template>
    </v-data-table>

    <!--  Dialogs  -->
    <ActionDialog :value="confirmDeleteDialog" width="700" divider confirm-text="Delete Member"
                  title="Delete Member" close-text="Cancel" confirm-color="error"
                  @confirm="deleteMember" @close="confirmDeleteDialog = false">
      <v-alert class="mt-6" border="bottom" colored-border type="warning" elevation="2">
        Are you sure you want to <strong>delete</strong> this member?
        <br><br>sort: {{memberToDelete.sort}}<br><br>
        This deletes it from the database, with no way to recover. Click "DELETE" below to proceed with deletion.
      </v-alert>
    </ActionDialog>

    <ErrorDialog ref="errorDialog"/>

    <snack :color="$_snack.color" :msg="$_snack.msg" v-model="$_snack.open" v-if="$_snack.cmpnt==='FirmMembersTable'"></snack>

  </div>
</template>

<script>
  import ActionDialog from "../../ActionDialog";
  import { PeopleAPIService } from "@/servicehandlers/PeopleAPIService";
  import { FirmsAPIService } from "@/servicehandlers/FirmsAPIService";

  const peopleAPIService = new PeopleAPIService();
  const firmsAPIService = new FirmsAPIService();

  export default {
    name: "FirmMembersTable",
    components: {ActionDialog},
    props: {
      firm: Object,
      notPopup: Boolean,
      default() {
        return {};
      }
    },
    data() {
      return {
        colHeaders: [
          {value: 'sort', text: 'Name', width: "300px",sortable: true, align: 'start'},
          {value: 'role', text: 'Role', width: "100px", sortable: true, align: 'start' },
          {value: 'mobile', text: 'Mobile', width: "140px", sortable: true, align: 'start' },
          {value: 'home', text: 'Home', width: "140px", sortable: true, align: 'start' },
          {value: 'direct', text: 'Direct', width: "140px", sortable: true, align: 'start' },
          {value: 'ext', text: 'Ext', width: "100px", sortable: true, align: 'start' },
          {value: 'fax', text: 'Fax', width: "140px", sortable: true, align: 'start' },
          {value: 'email', text: 'Email', width: "260px", sortable: true, align: 'start' },
          {value: 'licensed', text: 'Licensed', width: "120px", sortable: true, align: 'start'},
          {value: 'ce', text: 'CE', width: "90px", sortable: true, align: 'start' },
          {value: 'hired', text: 'Hired', width: "120px", sortable: true, align: 'start' },
          {value: 'hours', text: 'Hours', width: "90px", sortable: true, align: 'start' },
        ],
        colHeadersActions: {value: 'actions',text: '',width: "110px",sortable: false, align:'center'},
        confirmDeleteDialog: false,
        errors: [],
        loading:false,
        members: [],
        memberToDelete: {},
        search: '',
      }
    },

    created () {
      this.getMembersForThisFirm(this.firm.fid);
      if (this.$_can('member-edit')) this.colHeaders.unshift(this.colHeadersActions);
    },

  computed: {
      fields() {
        return this.colHeaders.map(a => a.value === 'actions' ? '' : a.value);//fields are all colHeaders except actions
      },
    },

    methods: {
      confirmDeleteMember(member) {
        this.memberToDelete = member;
        this.confirmDeleteDialog = true;
      },

      deleteMember() {
        peopleAPIService.deletePerson(this.memberToDelete.plid, this.$router).then((res) => {
          this.confirmDeleteDialog = false;
          this.getMembersForThisFirm(this.firm.fid);// refresh members table to show member is gone
          this.$store.dispatch('SET_SNACK', {cmpnt: 'FirmMembersTable', open: true, msg: 'Member deleted'});
        }).catch((e) => {
          this.confirmDeleteDialog = false;
          this.$refs.errorDialog.showError({
            errorMessage: "Failed to delete member.",
            forDevelopers: e
          })
        });
      },

      createNewMember(fid) {
        const params = {fid: fid, closer: this.username, staff: this.username};
        peopleAPIService.createPerson(params, this.$router).then((member) => {
          this.getMembersForThisFirm(this.firm.fid);
          this.editMember(member);
          }).catch((e) => {
            this.$refs.errorDialog.showError({
              errorMessage: "Failed to create new member.",
              forDevelopers: e
            })
          });
      },

      editMember(member) {
        if (!member.plid) return;
        let baseUrl = window.location.origin;
        window.open(baseUrl +  '/editMember/' +  member.plid, '_blank',
          'resizeable=yes, width=' + (980)
          + ', left=' + (500)
          + ', top=' + (22)
          + ', height=' + (520));
      },

      getMembersForThisFirm(id) {
        let FID = id.toString();
        firmsAPIService.getFirmPeople(FID, this.$router).then((data) => {
          if (data) {
            this.members = data;
            for (let member of this.members) {
              let salutation = '';
              let suffix = '';
              if (member.suffix) {
                suffix = member.suffix.substring(0,2) === ', ' ? member.suffix : ', ' + member.suffix;
              }
              if (member.salutation) salutation = '(' + member.salutation + ')';
              member.name = `${member.given || '' } ${member.surname}${suffix} ${salutation}`;
              member.sort = `${member.surname} ${member.given || ''}`;
            }
          }
        }).catch((e) => {
          this.$refs.errorDialog.showError({
            errorMessage: "Failed to create new member.",
            forDevelopers: e
          })
        });
      },
  },

}
</script>

<style scoped>

  .theme--light.v-data-table tbody tr:not(:last-child) td:last-child, .theme--light.v-data-table tbody tr:not(:last-child) th:last-child {
    padding: 0px;
  }

</style>
